/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import ReactPageScroller from 'react-page-scroller';
import { Collapse, IconButton } from '@mui/material';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import {
  Favorite, Share, MoreVert, ExpandLess,
} from '@mui/icons-material';
import { Link as Scroll } from 'react-scroll';
import Navigation from './Navigation';
import SBCard from './sbCard';
import flint3 from '../assets/flint/flint3.JPG';
import sb116 from '../assets/sb1/coversb1.jpg';
import sb25 from '../assets/sb2/sb25.JPG';
import sb33 from '../assets/sb3/storyboard3_img3.JPG';
import sbC6 from '../assets/sbC/sbC6.JPG';
import lsu42 from '../assets/LSU4/LSU42.JPG';
import sbCopper6 from '../assets/sbCopper/coppercover.jpg';
import sbLTK7 from '../assets/sbLTK/leadcover.jpg';
import '../assets/Home.css';

function HomeStoryboards({ sec2 }) {
  const [currentPage, setCurrentPage] = useState(0);
  const selectedTagsLength = useRef(0);
  const handlePageChange = (number) => {
    setCurrentPage(number);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const [value, setvalue] = useState('');
  // const [selectedTags, setSelectedTags] = useState([]);

  const handleOnchange = (val) => {
    setvalue(val);
  };

  const handleOnChangeClick = (val) => {
    // console.log(value, '; ', value.length);
    // if (!selectedTags.includes(val)) {
    //   setSelectedTags([...selectedTags, val]);
    // } else {
    //   setSelectedTags(selectedTags.filter((label) => val !== label));
    // }
    if (value.length === 0) {
      setvalue(val);
    } else if (!value.includes(val)) {
      setvalue(`${value},${val}`);
    } else if (value.includes(`,${val}`)) {
      setvalue(value.replace(`,${val}`, ''));
    } else {
      setvalue(value.replace(`${val}`, ''));
    }
  };

  const options = [
    { label: 'Water', value: 'Water' },
    { label: 'Lead', value: 'Lead' },
    { label: 'Flint', value: 'Flint' },
    { label: 'Research', value: 'Research' },
    { label: 'Louisiana', value: 'Louisiana' },
    { label: 'Environment', value: 'Environment' },
    { label: 'Action', value: 'Action' },
    { label: 'Education', value: 'Education' },
    { label: 'LSU', value: 'LSU' },
    { label: 'Students', value: 'Students' },
    { label: 'Solutions', value: 'Solutions' },
    { label: 'Health', value: 'Health' },
    { label: 'Policy', value: 'Policy' },
    { label: 'Impact', value: 'Impact' },
    { label: 'Virginia', value: 'Virginia' },
    { label: 'Government', value: 'Government' },
    { label: 'Chicago', value: 'Chicago' },
    { label: 'Justice', value: 'Justice' },
    { label: 'Mitigation', value: 'Mitigation' },
    { label: 'Copper', value: 'Copper' },
    { label: 'Testing', value: 'Testing' },
    { label: 'Procedures', value: 'Procedures' },
  ];

  const sb1Tags = ['Water', 'Lead', 'Research', 'Louisiana', 'Environment', 'Action'];
  const sb1 = {
    image: sb116, link: '/storyboard1', text: ' Empowering Citizens: Citizen’s Guide and Drinking Water Toolkit to Find Lead in Water Data', desc: 'This year a multi-university team worked with the Louisiana Environmental Action Network or LEAN to help residents address drinking water problems. LEAN has been developing a Citizen’s Guidebook to help communities address their environmental problems.',
  };

  const sb2Tags = ['Water', 'Education', 'Environment', 'Lead', 'Louisiana', 'LSU', 'Students'];
  const sb2 = {
    image: sb25, link: '/storyboard2', text: ' Educating and Empowering Students to Become Stewards of their Environment: Lead in Focus', desc: 'In the summers of 2019-2021, Dr. Adrienne Katner, Associate Professor at Louisiana State University (LSU) School of Public Health (SPH), took students out into the field to introduce them to environmental public health and citizen science.',
  };

  const sb3Tags = ['Water', 'Lead', 'Solutions', 'Louisiana', 'Health'];
  const sb3 = {
    image: sb33, link: '/storyboard3', text: ' Field-Testing Solutions to Remove Lead in Drinking Water', desc: 'In the summer of 2019, Louisiana State University School of Public Health partnered with Virginia Tech (VA Tech) to field-test water filters certified to remove lead.',
  };

  const sb4Tags = ['Policy', 'Water', 'Lead', 'Impact', 'Virginia', 'Environment', 'Government'];
  const sb4 = {
    image: lsu42, link: '/storyboardlsu4', text: 'Success Stories: Motivating Behavior Change, Reducing Lead Exposures, Impacting Policies and Influencing Lives', desc: 'In 2018, Virginia Tech’s was awarded a grant by the U.S. Environmental Protection Agency (U.S. EPA) to develop a citizen-centric approach to address lead in water issues.',
  };

  const sbFlintTags = ['Water', 'Lead', 'Flint'];
  const sbFlint = {
    image: flint3, link: '/flintstoryboard', text: 'How bad were water lead levels during the Flint, Michigan Water Crisis?', desc: 'In April 2014, the city of Flint switched its water source from Lake Huron to the local Flint River. It also suspended the addition of federally mandated corrosion control chemicals.',
  };

  const sbChicagoTags = ['Water', 'Investigation', 'Lead', 'Environment', 'Justice', 'Chicago'];
  const sbChicago = {
    image: sbC6, link: '/storyboardchicago', text: 'Investigating waterborne lead in collaboration with Environmental Justice organizations in the Chicago Metropolitan Area', desc: 'Consumer Reports, February 1993: Our Chicago subscribers turned out to have more serious lead problems than those in any other city we tested.',
  };

  const sbCopperTags = ['Mitigation', 'Lead', 'Copper',
    'Water', 'Testing', 'Procedures'];
  const sbCopper = {
    image: sbCopper6, link: '/storyboardcopper', text: 'Detecting and addressing copper in drinking water', desc: 'Learn more about at-home detection, test methods, and strategies to help residents and utilities address copper problems in drinking water.',
  };

  const sbLSUTags = ['Testing', 'Lead', 'Water', 'Procedures'];
  const sbLSU = {
    image: sbLTK7, link: '/storyboardltk', text: 'How to test your water for lead using at-home test kits', desc: 'Learn more about how to test for lead in drinking water using at-home test kits. This section describes which types of kits work better than others and how simple testing modifications can help you detect different types of lead in the water.',
  };

  const sbTags = [[sb1Tags, sb1], [sb2Tags, sb2],
    [sb3Tags, sb3], [sb4Tags, sb4], [sbFlintTags, sbFlint],
    [sbChicagoTags, sbChicago], [sbCopperTags, sbCopper],
    [sbLSUTags, sbLSU]];

  // const positionCheck = useWindowPosition('section1');

  const filter = () => {
    let filteredPosts = [];
    if (value === '') {
      filteredPosts = [sb1, sb2, sb3, sb4, sbFlint,
        sbChicago, sbCopper, sbLSU];
    } else {
      sbTags.forEach((post, i) => {
        post[0].forEach((tag) => {
          if (value.includes(tag) && !filteredPosts.includes(post[1])) filteredPosts.push(post[1]);
        });
      });
    }
    return <div className="storyboard-board">{filteredPosts.map((post) => <SBCard image={post.image} link={post.link} text={post.text} desc={post.desc} sec2={sec2} />)}</div>;
  };

  const displayTags = () => {
    const tagsToDisplay = [];
    options.forEach((option) => {
      if (value.includes(option.value)) {
        tagsToDisplay.push(<button type="button" onClick={() => handleOnChangeClick(option.value)} className="selected-tag">{option.label}</button>);
      } else {
        tagsToDisplay.push(<button type="button" onClick={() => handleOnChangeClick(option.value)} className="tag">{option.label}</button>);
      }
    });
    return <div className="tags-align">{tagsToDisplay.map((tag) => <div>{tag}</div>)}</div>;
  };

  const clearTags = () => {
    setvalue('');
  };

  const displaySelectedTags = () => {
    if (value.length > 0) {
      let tagsToDisplay = value.split(',');
      tagsToDisplay = tagsToDisplay.filter((tag) => tag.length > 0);
      selectedTagsLength.current = tagsToDisplay.length;
      if (selectedTagsLength.current > 0) {
        return (
          <div>
            <div className="tags-align">{tagsToDisplay.map((tag) => <div className="chosen-tag">{tag}</div>)}</div>
            <button type="button" aria-label="delete" onClick={() => clearTags()} className="delete-btn">
              <i className="fas fa-trash-alt" />
              Remove all
            </button>
          </div>
        );
      }
      return <div className="tags-align">{tagsToDisplay.map((tag) => <div className="chosen-tag">{tag}</div>)}</div>;
    }
    return '';
  };

  // <div className="center">
  //               <MultiSelect
  //                 onChange={handleOnchange}
  //                 options={options}
  //                 placeholder="Select tags . . ."
  //               />
  //             </div>
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true);
  }, []);

  // const positionCheck = useWindowPosition('s');

  return (
    <div>
      <div className="row">
        <div className="col-12 col-lg-10">
          <div>
            <div>
              <div className="slide-home">
                <div className="storyboard-board">
                  {filter()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeStoryboards;
