import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Storyboard1 from './components/Storyboard - 1/Storyboard1';
import Storyboard3 from './components/Storyboard - 3/Storyboard3';
import Storyboard2 from './components/Storyboard - 2/Storyboard2';
import StoryboardChicago from './components/Storyboard - Chicago/StoryboardChicago';
import StoryboardLSU4 from './components/Storyboard - LSU4/StoryboardLSU4';
import FlintStoryboard from './components/Storyboard - Flint/FlintStoryboard';
import StoryboardLTK from './components/Storyboard - LTK/StoryboardLTK';
import StoryboardCopper from './components/Storyboard - Copper/StoryboardCopper';
import WaterQuality from './components/WaterQuality';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/storyboard1" element={<Storyboard1 />} />
        <Route exact path="/storyboard2" element={<Storyboard2 />} />
        <Route exact path="/storyboard3" element={<Storyboard3 />} />
        <Route exact path="/storyboardchicago" element={<StoryboardChicago />} />
        <Route exact path="/storyboardlsu4" element={<StoryboardLSU4 />} />
        <Route exact path="/flintstoryboard" element={<FlintStoryboard />} />
        <Route exact path="/storyboardltk" element={<StoryboardLTK />} />
        <Route exact path="/storyboardcopper" element={<StoryboardCopper />} />
        <Route exact path="/waterquality" element={<WaterQuality />} />
      </Routes>
    </Router>
  );
}
