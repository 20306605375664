import React from 'react';
// eslint-disable-next-line no-unused-vars
import { iframe } from 'react-pdf';
import sb3PDF from '../../assets/sb3/sb3.pdf';
import Navigation from '../Navigation';
import '../../assets/pdf.css';

function Storyboard3() {
  return (
    <div className="pdf">
      <Navigation />
      <iframe className="pdf-view" frameBorder="0" title="title" src={sb3PDF} />
    </div>
  );
}

export default Storyboard3;
