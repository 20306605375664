/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
import React from 'react';
import Navigation from './Navigation';
import '../assets/InteractiveStyles.css';
import sbLTK20 from '../assets/sbLTK/sbLTK20.JPG';
import sb220 from '../assets/sb2/sb220.jpg';
import WQmp4 from '../assets/WaterQuality/WaterQualityFINAL.mp4';

function WaterQuality() {
  const divStyle = {
    backgroundImage: `url(${sb220})`,
    backgroundSize: 'cover',
  };
  return (
    <div style={divStyle}>
      <div className="page-full">
        <div className="flint-storyboard-1">
          <Navigation />
          <div className="page-container">
            <div className="slide-page">
              <div className="slide-content">
                <div className="bkg-color">
                  <div className="nav-section">
                    <div className="subtitle">
                      Lead In Water Resources
                    </div>
                    <div className="title">
                      Learn More: Water Quality
                    </div>
                  </div>
                </div>
                <hr />
                <div className="center">
                  <video className="slide-img" controls>
                    <source src={WQmp4} type="video/mp4" />
                  </video>
                </div>
                <div className="slide-text">
                  This these video helps people find data and information on the
                  drinking water and on good home water treatment systems.

                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  );
}

export default WaterQuality;
