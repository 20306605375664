/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Link as Scroll } from 'react-scroll';
import { red } from '@mui/material/colors';
import '../assets/Home.css';
import {
  Favorite, Share, MoreVert, ExpandMore, Info,
} from '@mui/icons-material';
import LandingImg from '../assets/landing.JPG';

const useStyles = (muiBaseTheme) => ({
});

const ExpandFxn = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({
  sec1, sec2, setSec2, expanded, setExpanded,
}) {
  const classes = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const resetPageTop = () => {
    document.getElementById('slide-page-home').scrollTop = 0;
    setSec2(true);
    if (expanded) setExpanded(false);
  };

  return (
    <div className="card-styles">
      <Card sx={{
        width: '70%', margin: 'auto',
      }}
      >
        <CardHeader
          action={(
            <IconButton aria-label="settings">
              <MoreVert />
            </IconButton>
        )}
        />
        <Typography variant="h2" color="text.secondary" style={{ fontFamily: 'Tahoma, Geneva, Verdana, sans-serif' }}>
          Lead-in-Water Project: Crowd the Tap Storyboards and Video
        </Typography>
        <Collapse in={sec1} {...(sec1 ? { timeout: 1000 } : {})} collapsedHeight={50}>
          <CardMedia
            className="card-media"
            component="img"
            style={{
              width: '10%',
              maxWidth: '500px',
              margin: 'auto',
              marginTop: '5px',
            }}
            image={LandingImg}
            alt="Untapping the Crowd"
          />
        </Collapse>
        <CardActions disableSpacing>
          <div><ExpandMore style={{ fontSize: '5rem' }} /></div>
          <Typography variant="h3" color="text.secondary" style={{ fontWeight: 'bold', fontFamily: 'Franklin Gothic Medium' }}>
            Explore Storyboards Below
          </Typography>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent style={{ width: '95%', margin: 'auto' }}>
            <hr />
            <Typography variant="h4" color="text.secondary" style={{ marginBottom: '10px' }}>About</Typography>
            <Typography variant="h6" color="text.secondary" style={{ fontFamily: 'Tahoma, Geneva, Verdana, sans-serif', marginBottom: '10px' }}>
              This is an EPA-funded project to develop a
              consumer-centric resource which residents can go
              to get information about lead in water risks. There
              are two primary resources
              on this site:
            </Typography>
            <Typography variant="h6" color="text.secondary" style={{ fontFamily: 'Tahoma, Geneva, Verdana, sans-serif' }}>
              <ol>
                <li color="text.secondary" style={{ fontFamily: 'Tahoma, Geneva, Verdana, sans-serif', marginBottom: '10px' }}>
                  Query system to identify the best treatment system for your home (in progress).
                </li>
                <li color="text.secondary" style={{ fontFamily: 'Tahoma, Geneva, Verdana, sans-serif' }}>
                  Resources for citizens, water utilities and regulators (in progress).
                </li>
              </ol>
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}
