import React from 'react';
// eslint-disable-next-line no-unused-vars
import { iframe } from 'react-pdf';
import flintPDF from '../../assets/flint/flint.pdf';
import Navigation from '../Navigation';
import '../../assets/pdf.css';

function StoryboardFlint() {
  return (
    <div className="pdf">
      <Navigation />
      <iframe className="pdf-view" frameBorder="0" title="title" src={flintPDF} />
    </div>
  );
}

export default StoryboardFlint;
