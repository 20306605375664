/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import ReactPageScroller from 'react-page-scroller';
import {
  Collapse, IconButton,
  CssBaseline, Typography,
} from '@mui/material';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import {
  Favorite, Share, MoreVert, ExpandLess,

  ExpandMore,
} from '@mui/icons-material';
import { Link as Scroll } from 'react-scroll';
import Navigation from './Navigation';
import HomeStoryboards from './HomeStoryboards';
import Landing from './Landing';
import SBCard from './sbCard';
import flint3 from '../assets/flint/flint3.JPG';
import sb118 from '../assets/sb1/sb115.JPG';
import sb25 from '../assets/sb2/sb22.JPG';
import sb33 from '../assets/sb3/storyboard3_img3.JPG';
import sbC6 from '../assets/sbC/sbC6.JPG';
import lsu42 from '../assets/LSU4/LSU42.JPG';
import sbCopper6 from '../assets/sbCopper/sbCopper6.JPG';
import sbLTK7 from '../assets/sbLTK/sbLTK10.JPG';
import LSU421 from '../assets/LSU4/LSU421.jpg';
import flint24 from '../assets/flint/flint24.jpg';
import '../assets/Home.css';
import Card from './Card';

const home = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const selectedTagsLength = useRef(0);
  const numSections = 2;

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const [value, setvalue] = useState('');
  // const [selectedTags, setSelectedTags] = useState([]);

  const handleOnchange = (val) => {
    setvalue(val);
  };

  const handleOnChangeClick = (val) => {
    // console.log(value, '; ', value.length);
    // if (!selectedTags.includes(val)) {
    //   setSelectedTags([...selectedTags, val]);
    // } else {
    //   setSelectedTags(selectedTags.filter((label) => val !== label));
    // }
    if (value.length === 0) {
      setvalue(val);
    } else if (!value.includes(val)) {
      setvalue(`${value},${val}`);
    } else if (value.includes(`,${val}`)) {
      setvalue(value.replace(`,${val}`, ''));
    } else {
      setvalue(value.replace(`${val}`, ''));
    }
  };

  const [sec1, setSec1] = useState(false);
  const [sec2, setSec2] = useState(false);
  const [inSec2, setInSec2] = useState(false);
  const [sbHeader, setSbHeader] = useState(false);

  const styles = {
    minHeight: '100vh',
    backgroundImage: `url(${LSU421})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  const [checked, setChecked] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const resetPageTop = () => {
    document.getElementById('slide-page-home').scrollTop = 0;
    setSec2(true);
    if (expanded) setExpanded(false);
  };

  useEffect(() => {
    setSec1(true);
    setChecked(true);
  }, []);

  return (
    <div style={styles}>
      <div className="content-container">
        <div className="section1" id="section1">
          <CssBaseline />
          <Navigation />
          <Card
            sec1={sec1}
            sec2={sec2}
            setSec2={setSec2}
            expanded={expanded}
            setExpanded={setExpanded}
          />
          <div className="section2" id="section2">
            <div className="main-storyboards-content">
              <div className="title-home-storyboards">
                <hr style={{ margin: 0 }} />
                Learn More About the Storyboards
                <hr style={{ margin: 0 }} />
              </div>
              <div id="slide-page-home" className="slide-page-home">
                <HomeStoryboards sec2={sec2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default home;
