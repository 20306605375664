import React from 'react';
// eslint-disable-next-line no-unused-vars
import { iframe } from 'react-pdf';
import sb2PDF from '../../assets/sb2/sb2.pdf';
import Navigation from '../Navigation';
import '../../assets/pdf.css';

function Storyboard2() {
  return (
    <div className="pdf">
      <Navigation />
      <iframe className="pdf-view" frameBorder="0" title="title" src={sb2PDF} />
    </div>
  );
}

export default Storyboard2;
