import React from 'react';
import {
  Navbar, Container, Nav, NavDropdown,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../assets/Navigation.css';

function Navigation() {
  return (
    <div className="navbar-page">
      <Navbar className="navbar-container" collapseOnSelect expand="md" variant="dark">
        <Container>
          <Navbar.Brand href="#home">
            <NavLink to="/">
              <i className="fas fa-tint" />
              Lead In Water
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown className="dropdown-label" title="Storyboards" id="collasible-nav-dropdown">
                <NavDropdown.Item>
                  <NavLink to="/storyboard1">
                    Storyboard - Empowering Citizens
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <NavLink to="/storyboard2">
                    Storyboard - Empowering Students
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <NavLink to="/storyboard3">
                    Storyboard - Testing Solutions
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <NavLink to="/flintstoryboard">
                    Storyboard - Lead in Flint
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <NavLink to="/storyboardlsu4">
                    Storyboard - Success Stories
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <NavLink to="/storyboardchicago">
                    Storyboard - Investigating Chicago
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <NavLink to="/storyboardltk">
                    Storyboard - Lead Testing Kit
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <NavLink to="/storyboardcopper">
                    Storyboard - Copper in Water
                  </NavLink>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="dropdown-label" href="/waterquality">Videos</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div>
  );
}

export default Navigation;
