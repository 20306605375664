/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Collapse } from '@mui/material';

const sbCard = ({
  image, link, text, desc, sec2,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  }, []);

  return (
    <Link to={link}>
      <div className="sb-container">
        <Card sx={{ height: 400, backgroundColor: '#cccccc' }}>
          <CardMedia
            component="img"
            height="180"
            image={image}
            alt={image}
          />
          <div style={{ backgroundColor: '#cccccc' }}>
            <CardContent>
              <Typography gutterBottom component="div" className="sbCard-text" style={{ fontFamily: 'Franklin Gothic Medium', fontWeight: 'bold', fontSize: '13px' }}>
                {text}
              </Typography>
              <Typography className="sbCard-text" style={{ fontSize: '11px' }} color="text.secondary">
                {desc}
              </Typography>
            </CardContent>
          </div>
        </Card>
      </div>

    </Link>
  );
};

export default sbCard;
