/* eslint-disable no-unused-vars */
import React from 'react';
import { iframe } from 'react-pdf';
import sbLTKPDF from '../../assets/sbLTK/sbLTK.pdf';
import Navigation from '../Navigation';
import '../../assets/pdf.css';
import '../../assets/InteractiveStyles.css';
import '../../assets/FlintStoryboard.css';

function StoryboardLTK() {
  return (
    <div className="pdf">
      <Navigation />
      <iframe className="pdf-view" frameBorder="0" title="title" src={sbLTKPDF} />
    </div>
  );
}

export default StoryboardLTK;
