import React from 'react';
// eslint-disable-next-line no-unused-vars
import { iframe } from 'react-pdf';
import sbCopperPDF from '../../assets/sbCopper/sbCopper.pdf';
import Navigation from '../Navigation';
import '../../assets/pdf.css';

function StoryboardCopper() {
  return (
    <div className="pdf">
      <Navigation />
      <iframe className="pdf-view" frameBorder="0" title="title" src={sbCopperPDF} />
    </div>
  );
}

export default StoryboardCopper;
